<app-header></app-header>
<div class="row justify-content-center" style="margin-bottom: 15px; margin-top: 10px" *ngIf="infoEvento.servico != 0 && infoEvento.servico != 3">
  
  <div class="col-lg-5" style="padding: 50px">
    <h3 class="visible-xs" style="text-align: center;" *ngIf="infoEvento.logoAfiliado">
      <img  src="https://img.pipooh.com.br/{{ this.infoEvento.logoAfiliado }}" alt="" width="50%">
    </h3>
    <h3 class="hidden-xs" style="text-align: center;" *ngIf="infoEvento.logoAfiliado">
      <img  src="https://img.pipooh.com.br/{{ this.infoEvento.logoAfiliado }}" alt="" width="30%">
    </h3>
    <p class="maeTitulo" style="color: #000; font-weight: 300;text-align: center" 
    *ngIf="infoEvento.nomeAfiliado">
       {{infoEvento.nomeAfiliado}}
  </p>
      <hr *ngIf="infoEvento.nomeAfiliado"> 

    <h2 class="maeTitulo" style="color: #01b1af; font-weight: 700">
      {{ this.infoEvento.nome }}
    </h2>
    <h4 class="maeTitulo" style="color: #f8b3cf">
      Evento: 
      <span *ngIf="infoEvento.tipo == 'aniversario'">Aniversário</span>
      <span *ngIf="infoEvento.tipo == 'mesversario'">Mesversário</span>
      <span *ngIf="infoEvento.tipo == 'cha-fralda'">Chá Fralda</span>
      <span *ngIf="infoEvento.tipo == 'boas-vindas'">Boas Vindas</span>
      <span *ngIf="infoEvento.tipo == 'fute-fralda'">Fut Fralda</span>
      <span *ngIf="infoEvento.tipo == 'cha-bar'">Chá bar</span>
      <span *ngIf="infoEvento.tipo == 'Chá Empresarial'">Chá Empresarial</span>
      <span *ngIf="infoEvento.tipo == 'batizado'">Batizado</span>
      <span *ngIf="infoEvento.tipo == 'cha-revelacao'">Chá Revelação</span>
      <span *ngIf="infoEvento.tipo == 'cha-rifa'">Chá Rifa</span>
    </h4>
    <p class="bannerParagraph" *ngIf="this.infoEvento.mensagem != null">
      {{ this.infoEvento.mensagem }}
    </p>
    <p class="bannerParagraph" *ngIf="this.infoEvento.mensagem == null">
      Estou muito feliz por vocês participarem do meu evento!
    </p>
    <div class="row" style=" text-align: center" *ngIf="infoEvento.servico != 0 || infoEvento.servico != 3">
    
      <div class="col-lg-6" style="margin-bottom: 10px" *ngIf="infoEvento.servico == 1">
        <a class="btn btn-primary"onclick="document.getElementById('openModalPresenca').click();"  (click)="ancora('produtos')" style="border-radius: 25px; color: #fff;">Confirmar Presença</a>
      </div>
      <div class="col-lg-6" style="margin-bottom: 10px" *ngIf="infoEvento.servico == 1">
        <a class="btn btn-primary" (click)="ancora('produtos')" style="border-radius: 25px;color: #fff;" >Presentear agora</a>
      </div>
      <div class="col-lg-12" style="margin-bottom: 10px" *ngIf="infoEvento.servico != 1">
        <a class="btn btn-primary" (click)="ancora('produtos')" style="border-radius: 25px;color: #fff; width: 100%" >Presentear agora</a>
      </div>
      <!-- <div class="col-lg-12" style="margin-bottom: 10px;">
        <a  onclick="document.getElementById('openModalSaibaMais').click();"
          style="border-radius: 25px; text-align: center; color:#01B1AF;cursor: pointer">Saiba mais</a>
      </div> -->
    </div>
  </div>
  <div class="col-lg-5" style="padding: 25px; text-align: center">
    <img style="max-width: 100%; max-height: 400px; min-width: 100%" class="imagemPerfil"
      *ngIf="infoEvento.festou == false || this.infoEvento.imagem.indexOf('festou') == -1" src="https://img.pipooh.com.br/{{ infoEvento.convitePrincipal == false ? this.infoEvento.imagem : infoEvento.convitePersonalizado }}" alt="" />
    <img style="max-width: 100%; max-height: 400px; min-width: 50%" class="imagemPerfil"
      *ngIf="infoEvento.festou == true && this.infoEvento.imagem.indexOf('festou') != -1" [src]="this.infoEvento.imagem" alt="" />
  </div>
</div>

<div class="row justify-content-center" style="padding: 25px" *ngIf="infoEvento.servico != 0 && infoEvento.servico != 3">
  <div class="col-lg-2 col-md-6 col-sm-10 col-xs-10" style="margin-bottom: 30px; margin-left: 10px; margin-right: 10px">
    <div class="row">
      <div class="col-12 maeDados" style="padding-left: 0; padding-right: 0">
        <!-- <img style="width: 100%;" class="imagemPerfil" src="https://pipooh.com.br/{{this.infoMamae.UserFoto}}" alt=""> -->
        <div style="padding: 15px">
          <h2 class="maeTexto" style="margin-bottom: -10px" *ngIf="!infoEvento.fkChaOrganizador">
            <img src="assets/img/convidado/bebe.svg" />
            {{ this.infoEvento.nomeFilho }}
          </h2>

          <!-- <br /> -->
          <!-- <p style="font-size: 15px; color: #ffffff" *ngIf="infoEvento.servico != false">
            <img src="assets/img/convidado/calendario.svg" /> Presentear até: 
            {{ this.infoEvento.vencimento | date: "dd/MM/yyyy" }}
          </p> -->
          <div *ngIf="this.infoEvento.rifaAtiva == 1 && this.infoEvento.tipo != 'cha-revelacao'"
            style="margin-bottom: 5px">
            <h4 class="mt-4" style="color: #f8b3cf">Rifa</h4>
            <p style="color: #ffffff; font-size: 15px; margin-bottom: -5px">
              R${{ this.bilhetesValor }}(Produto) = 1 número da rifa.
            </p>
            <!-- <br> -->

            <div class="col-12 btn" onclick="document.getElementById('openModalChaRifa').click();" style="
                text-align: center;
                align-items: center;
                margin-bottom: -20px;
                border-radius: 25px;
                color: #01b1af;
                background-color: #ffffff;
              ">
              Ver Prêmios
            </div>
          </div>
          <div *ngIf="this.infoEvento.statusRevelacao == true && this.infoEvento.tipo=='cha-revelacao'"
            style="margin-bottom: 5px">
            <p style="color: #ffffff; margin-bottom: -25px">
              Mamãe está organizando:
            </p>
            <h4 class="mt-4" style="color: #f8b3cf">Chá revelação</h4>
            <div class="row" *ngIf="this.infoEvento.gemeos=='0' && this.votosRevelacao[0].menina!=null"
              style="color: white;">
              <p style="color: white;">Votos:</p>
              <div class="col-6" style="text-align:center ;">
                <p style="color: white;">Menina: {{this.votosRevelacao[0].menina}}</p>

              </div>
              <div class="col-6" style="text-align:center ;">
                <p style="color: white;">Menino: {{this.votosRevelacao[0].menino}}</p>

              </div>
            </div>
            <div class="row justify-content-center"
              *ngIf="this.infoEvento.gemeos=='1' && this.votosRevelacao[0].menina!=null" style="color: white;">
              <p style="color: white;">Votos:</p>
              <div class="col-6" style="text-align:center ;">
                <p style="color: white;">Meninas: {{this.votosRevelacao[0].meninas}}</p>

              </div>
              <div class="col-6" style="text-align:center ;">
                <p style="color: white;">Meninos: {{this.votosRevelacao[0].meninos}}</p>

              </div>
              <div class="col-6" style="text-align:center ;">
                <p style="color: white;">Casal: {{this.votosRevelacao[0].casal}}</p>

              </div>
            </div>

            <div class="col-12 btn" onclick="document.getElementById('openModalChaRevelacao').click();"
              (click)="getPremiosRevelacao()" style="
              text-align: center;
              align-items: center;
              margin-bottom: -20px;
              border-radius: 25px;
              color: #01b1af;
              background-color: #ffffff;
            ">
              Ver como funciona
            </div>
          </div>

          <!-- <br> -->
          <div class="mt-4" *ngIf="infoEvento.tipo != 'Chá Empresarial' && !infoEvento.fkChaOrganizador">
            <h4 class="mt-4" style="color: #f8b3cf">Dados da Festa:</h4>

            <div>
              <p style="color: #ffffff">
                Festa: <span *ngIf="this.infoEvento.festaTipo">{{ this.infoEvento.festaTipo }}</span> 
                <br>
                Data: {{
                this.infoEvento.data | date: "dd/MM/yyyy"
                }}
                <br>
                Horário: {{ this.infoEvento.hora.substr(0,5) }}
                <br>
                Endereço/link:{{ this.infoEvento.endereco }}
              </p>
            </div>

            <div class="col-12 btn" 
            onclick="document.getElementById('openModalPresenca').click();" style="
                text-align: center;
                align-items: center;
                border-radius: 25px;
                color: #01b1af;
                background-color: #ffffff;
              " *ngIf="infoEvento.servico == 1">
              Confirme presença
            </div>
          </div>
        </div>
        <div style="
            background-color: #f8b3cf;
            width: 100%;
            border-radius: 10px;
            padding: 10px;
          ">
          <p style="text-align: center;color:#fff" *ngIf="this.infoEvento.estacionamento || this.infoEvento.outros" >Informações úteis</p>
          <p *ngIf="this.infoEvento.estacionamento" style="color: #6a6a6a; font-style: italic">
           Estacionamento: {{ this.infoEvento.estacionamento }}
          </p>
          <p *ngIf="this.infoEvento.outros" style="color: #6a6a6a; font-style: italic">
           Outros: {{ this.infoEvento.outros }}
          </p>
          <p style="text-align: center;color:#fff" *ngIf="this.infoEvento.tamVestuario || this.infoEvento.tamSapato || infoEvento.dicas" >Dicas para presentear</p>
          <p *ngIf="this.infoEvento.tamVestuario" style="color: #6a6a6a; font-style: italic">
           Tam.Vestuário: {{ this.infoEvento.tamVestuario }}
          </p>
          <p *ngIf="this.infoEvento.tamSapato" style="color: #6a6a6a; font-style: italic">
            Tam.Sapato: {{ this.infoEvento.tamSapato }}
          </p>
          <p *ngIf="this.infoEvento.dicas" style="color: #6a6a6a; font-style: italic">
            Personagens, produtos e temas favoritos: {{ this.infoEvento.dicas }}
          </p>
         <hr>
         <h3>Você pode presentear aqui na PIPOOH de forma prática e fácil.</h3>
          <p class="bannerParagraph">
            Aqui seu presente é na medida certa. Sem repetições ou tamanhos errados. 
          </p>
          <div style="text-align: center;">
            <a  onclick="document.getElementById('openModalSaibaMais').click();"
            style="border-radius: 25px; text-align: center; color:#01B1AF;cursor: pointer">Saiba mais</a>
          </div>
         
        </div>
      </div>
    </div>
    <!-- <div class="row ">
      <div class="col-12">
        <img src="assets/img/banners/BANNER DIA DAS MÃES.jpg" alt="" width="100%" />
      </div>
    </div> -->
  </div>

  <!-- <div class="col-lg-2 col-md-6 col-sm-10 col-xs-10" style="margin-bottom: 30px; margin-left: 10px; margin-right: 10px">
    <div class="filtro categorias">
      <h3>Categorias</h3>
      <ul>
        <li>
          <mat-checkbox>
          </mat-checkbox> <span> Todos</span>
        </li>
        <li class="mb-1" *ngFor="let cat of categorys">
          <mat-checkbox>
          </mat-checkbox><span>{{cat.CategoriaName}}
            ({{cat.Contador}})</span><span *ngIf="loadingProdutos == true">
            <div class="m-loader m-loader--brand"></div>
          </span>
        </li>

      </ul>
    </div>
  </div> -->

  <div class="col-xs-12 col-md-7 p-xs-1" *ngIf="infoEvento.servico != 0 || infoEvento.servico != 3">
    
    <div class="row pt-md-5">
      <div class="col-xs-12 col-md-3">
        <div class="input-group mb-3" style="border-radius: 25px">
          <select class="form-control" [(ngModel)]="this.categoriaSelecionada" style="width: 100%"
            (change)="getProducts()" style="border-radius: 25px">
            <option value="" style="border-radius: 25px">Categorias</option>
            <option *ngFor="let cat of categorys" style="border-radius: 25px" value="{{ cat.CategoryID }}">
              {{ cat.CategoriaName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="input-group mb-3" style="border-radius: 25px">
          <select class="form-control" [(ngModel)]="this.lojaSelecionada" style="width: 100%" (change)="getProducts()"
            style="border-radius: 25px">
            <option value="">Lojas</option>
            <option value="0">Drogaria Estrela</option>
            <!-- <option value="1">Toymania</option> -->
            <!-- <option value="2">Limara</option> -->
            <!-- <option value="3">Chicco</option> -->
            <option value="4">BabyStock</option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="input-group mb-3" style="border-radius: 25px">
          <select class="form-control" [(ngModel)]="this.generoSelecionado" style="width: 100%" (change)="getProducts()"
            style="border-radius: 25px">
            <option value="">Gênero</option>
            <option value="UNISSEX">Unissex</option>
            <option value="MENINO">Menino</option>
            <option value="MENINA">Menina</option>
          </select>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-md-2">
        <div class="input-group mb-3" style="border-radius: 25px">
          <select class="form-control" [(ngModel)]="this.idadeSelecionada" style="width: 100%" (change)="getProducts()"
            style="border-radius: 25px">
            <option value="">Idade</option>
            <option *ngFor="let idade of idades" value="{{idade}}">
              {{idade}}
            </option>

          </select>
        </div>
      </div> -->
      <div class="col-xs-12 col-md-3">
        <div class="input-group mb-3" style="border-radius: 25px">
          <select class="form-control" [(ngModel)]="this.ordemSelecionada" style="width: 100%" (change)="getProducts()"
            style="border-radius: 25px">
            <option value="">Ordenar</option>
            <option value="ASC">Menor preço</option>
            <option value="DESC">Maior preço</option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 col-md-12">
        <div class="input-group mb-3" style="border-radius: 25px">
          <input style="border-radius: 25px" type="text" class="form-control" (keyup.enter)="getProducts()"
            [(ngModel)]="this.busca" placeholder="Buscar um produto..." aria-label="Recipient's username"
            aria-describedby="button-addon2" />
          <div class="input-group-append mr-md-5">
            <button style="border-radius: 25px" class="btn btn-outline-secondary" (click)="getProducts()" type="button"
              id="button-addon2">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="this.products" id="produtos">
      <div class="col-xs-12 col-md-4" *ngFor="
          let prod of products | slice: (page - 1) * pageSize:page * pageSize
        ">
        <div class="row" style="text-align: center">
          <div *ngIf="presentearValido" class="col-md-12 col-5" style="margin-bottom: 10px">
            <img *ngIf="!prod.Produto.fkProdutos_LojaIntegrada" (click)="openProduto(prod.Produto)"
              src="{{ this.urlImage(prod.Produto.ProductsImage) }}" class="" alt="" style="
                max-height: 250px;
                max-width: 100%;
                border-radius: 25px;
                box-shadow: 0px 3px 6px #00000029;
              " />
            <img *ngIf="prod.Produto.fkProdutos_LojaIntegrada" (click)="openProduto(prod)"
              src="{{ this.urlImageLoja(prod.Produto.ProductsImage) }}" class="" alt="" style="
                max-height: 250px;
                max-width: 100%;
                border-radius: 25px;
                box-shadow: 0px 3px 6px #00000029;
              " />
          </div>
          <div *ngIf="!presentearValido" class="col-md-12 col-5" style="margin-bottom: 10px">
            <img *ngIf="!prod.Produto.fkProdutos_LojaIntegrada" src="{{ this.urlImage(prod.Produto.ProductsImage) }}" class="" alt=""
              style="
                max-height: 250px;
                max-width: 100%;
                border-radius: 25px;
                box-shadow: 0px 3px 6px #00000029;
              " />
            <img *ngIf="prod.Produto.fkProdutos_LojaIntegrada" src="{{ this.urlImageLoja(prod.Produto.ProductsImage) }}" class="" alt=""
              style="
                max-height: 250px;
                max-width: 100%;
                border-radius: 25px;
                box-shadow: 0px 3px 6px #00000029;
              " />
          </div>

          <div class="col-md-12 col-7" style="text-align: center">
            <div style="min-height: 70px; max-height: 90px">
              <h6 class="" style="color: #6a6a6a">{{ prod.Produto.ProductsName }}</h6>
            </div>
            <div class="row justify-content-center">
              <div class="col-12" style="text-align: center">
                <h4 class="mt-1" style="color: #01b1af">
                  R$ {{ this.formatDecimal(prod.Produto.ProductsPrice) }}
                </h4>
              </div>
              <div *ngIf="presentearValido" class="col-6 btn btn-primary" (click)="openProduto(prod.Produto)" style="
                  text-align: center;
                  margin-bottom: 10px;
                  border-radius: 25px;
                  color: white;
                ">
                <!-- <img class="fillCart" src="/assets/img/convidado/cart.svg" /> -->
                Presentear
              </div>
              <div *ngIf="!presentearValido" class="col-6 btn btn-primary" style="
                  text-align: center;
                  margin-bottom: 10px;
                  border-radius: 25px;
                  color: white;
                ">
                <!-- <img class="fillCart" src="/assets/img/convidado/cart.svg" /> -->
                Evento encerrado
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-3" *ngIf="this.products.length == 0">
      <div class="col-xs-12 col-md-4 py-2">
        <div class="media">
          <h3>Produto não encontrado</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 justify-content-center">
        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          [collectionSize]="products.length > 0 ? products.length : 0" [rotate]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>


<div class="row justify-content-center confirmacao" style="padding: 25px; background: #01B1AF" *ngIf="infoEvento.servico == 0 || infoEvento.servico == 3">
  <div class="col-xs-12 col-md-7 p-xs-1" >
    <div class="d-lg-none">
      <img style="width: 100%;  min-width: 50%; margin-bottom: 20px" class="imagemPerfil"
      *ngIf="infoEvento.festou == false || this.infoEvento.imagem.indexOf('festou') == -1" src="https://img.pipooh.com.br/{{ this.infoEvento.imagem }}" alt="" />
    <img style="width: 100%;  min-width: 50%;margin-bottom: 20px" class="imagemPerfil"
      *ngIf="infoEvento.festou == true && this.infoEvento.imagem.indexOf('festou') != -1" [src]="this.infoEvento.imagem" alt="" />
    </div>
    <div class="d-none d-lg-block">
      <img style="width: 75%;  min-width: 50%" class="imagemPerfil"
      *ngIf="infoEvento.festou == false || this.infoEvento.imagem.indexOf('festou') == -1" src="https://img.pipooh.com.br/{{infoEvento.convitePrincipal == false ? this.infoEvento.imagem : infoEvento.convitePersonalizado }}" alt="" />
    <img style="width: 75%;  min-width: 50%" class="imagemPerfil"
      *ngIf="infoEvento.festou == true && this.infoEvento.imagem.indexOf('festou') != -1" [src]="this.infoEvento.imagem" alt="" />
    </div>
    
  </div>
  <div class="col-lg-4 col-md-6 col-sm-10 col-xs-10" style="margin-bottom: 30px; margin-left: 10px; margin-right: 10px">
    <div class="row">
      <div class="col-12 maeDados" style="padding-left: 0; padding-right: 0">
        <!-- <img style="width: 100%;" class="imagemPerfil" src="https://pipooh.com.br/{{this.infoMamae.UserFoto}}" alt=""> -->
        <div style="padding: 15px">
          <!-- <h2 class="maeTitulo" style="color: #f8b3cf">
            <span *ngIf="infoEvento.tipo == 'aniversario'">Aniversário</span>
            <span *ngIf="infoEvento.tipo == 'mesversario'">Mesversário</span>
            <span *ngIf="infoEvento.tipo == 'cha-fralda'">Chá Fralda</span>
            <span *ngIf="infoEvento.tipo == 'boas-vindas'">Boas Vindas</span>
            <span *ngIf="infoEvento.tipo == 'fute-fralda'">Fut Fralda</span>
            <span *ngIf="infoEvento.tipo == 'cha-bar'">Chá bar</span>
            <span *ngIf="infoEvento.tipo == 'Chá Empresarial'">Chá Empresarial</span>
            <span *ngIf="infoEvento.tipo == 'batizado'">Batizado</span>
            <span *ngIf="infoEvento.tipo == 'cha-revelacao'">Chá Revelação</span>
            <span *ngIf="infoEvento.tipo == 'cha-rifa'">Chá Rifa</span>
          </h2> -->
          <h3 style="text-align: center;" *ngIf="infoEvento.logoAfiliado">
            <img src="https://img.pipooh.com.br/{{ this.infoEvento.logoAfiliado }}" alt="" width="30%">
          </h3>
          <p class="maeTitulo" style="color: #ffffff; font-weight: 300;text-align: center" 
          *ngIf="infoEvento.nomeAfiliado">
             {{infoEvento.nomeAfiliado}}
          </p>
            <hr *ngIf="infoEvento.nomeAfiliado"> 

          <h2 class="maeTitulo" style="color: #ffffff; font-weight: 700">
            {{ this.infoEvento.nome }}
          </h2>
          
          <div class="" *ngIf="infoEvento.tipo != 'Chá Empresarial' && !infoEvento.fkChaOrganizador">
            <h4 class="" style="color: #F9E291">Dados da Festa:</h4>

            <div>
              <p style="color: #ffffff">
                Festa: <span *ngIf="this.infoEvento.festaTipo">{{ this.infoEvento.festaTipo }}</span> 
                <br>
                Data: {{
                this.infoEvento.data | date: "dd/MM/yyyy"
                }}
                <br>
                Horário: {{ this.infoEvento.hora.substr(0,5) }}
                <br>
                Endereço/link:{{ this.infoEvento.endereco }}

              </p>
              <!-- <p style="color: #ffffff">
                
              </p>
              <p style="color: #ffffff"></p> -->
            </div>

            <div class="col-12 btn" onclick="document.getElementById('openModalPresenca').click();" style="
                text-align: center;
                align-items: center;
                border-radius: 25px;
                color: #01b1af;
                background-color: #F9E291;
              " *ngIf="infoEvento.festou == false">
              Confirme presença
            </div>
          </div>
        </div>
        <div style="
            background-color: #fff;
            width: 100%;
            border-radius: 10px;
            padding: 10px;
          ">
          <p style="text-align: center">Mensagem aos convidados:</p>
          <p *ngIf="this.infoEvento.mensagem != null" style="color: #6a6a6a; font-style: italic">
            {{ this.infoEvento.mensagem }}
          </p>
          <p *ngIf="this.infoEvento.mensagem == null" style="color: #6a6a6a; font-style: italic">
            Estou muito feliz por vocês participarem do meu evento!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modais a partir daqui -->
<ng-template #chaRifa let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="padding: 8px; border: 8px solid #04cdcd">
      <div class="" style="width: 100%; display: block; text-align: center">
        <img width="30%" src="assets/img/convidado/rifa.png" />

        <h5 class="modal-title" style="
            text-align: center;
            color: #04cdcd;
            font-weight: 900;
            font-size: 25px;
          ">
          RIFA:
          <br />
        </h5>
        <h5 class="modal-title" style="
            text-align: center;
            color: #04cdcd;
            font-weight: 900;
            font-size: 25px;
          ">
          {{ this.infoEvento.nomeFilho }}
        </h5>
      </div>
      <br />
      <div class="form-row" style="
          display: block;
          padding-left: 10px;
          padding-top: 20px;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          margin: auto;
          text-align: center;
        ">
        Valor do Bilhete:
        <span style="
            color: #04cdcd;
            font-weight: 900;
            padding-left: 3px;
            padding-right: 3px;
            margin: auto;
          ">R$<span id="ContentPlaceHolder1_phValBilhete">{{
            this.bilhetesValor
            }}</span></span>
      </div>

      <div *ngFor="let premio of this.premiosRifa" style="
          background-color: #cc61ce00;
          border-radius: 12px;
          border: 1px solid #04cdcd;
          margin-top: 20px;
          padding-bottom: 6px;
          width: 100%;
        ">
        <div class="row">
          <div class="col-3" style="text-align: center">
            <i class="fa fa-trophy mx-2" style="font-size: 40px"></i>
          </div>
          <div class="col-7">
            <p style="margin-top: 5px; margin-bottom: 0px; margin-left: 10px">
              Premio :
              <span style="margin-left: 10px">{{ premio.premio }}</span>
            </p>
            <span style="margin-left: 10px"></span>
          </div>
        </div>
      </div>

      <div class="form-row" style="padding-top: 10px; padding-bottom: 0px; width: 100%; text-align: center;">
        <a id="PremiosRifa" style=" margin: auto; font-weight: 700">A cada R${{ this.bilhetesValor }} em produtos você ganha 1 bilhete e concorre ao prêmio.</a><br />
      </div>
      <p id="rifaMsgOut" style="text-align: center; margin: auto">
        <br /><a class="btn btn-outline-primary"
          href="https://www.youtube.com/watch?v=X9FNwLGnAm4&amp;ab_channel=Pipooh-MeuCh%C3%A1Fralda" target="_blank">
          Ainda tem dúvidas? Clique aqui.
        </a>
      </p>
    </div>
  </div>
</ng-template>

<button id="openModalChaRifa" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(chaRifa)"
  data-target="#openModalChaRifa"></button>

<!-- Modais a partir daqui -->
<ng-template #chaRevelacao let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="padding: 8px; border: 8px solid #04cdcd">
      <div class="" style="width: 100%; display: block; text-align: center">
        <img width="30%" src="assets/img/convidado/rifa.png" />

        <h5 class="modal-title" style="
            text-align: center;
            color: #04cdcd;
            font-weight: 900;
            font-size: 25px;
          ">
          Chá Revelação
          <br />
        </h5>

      </div>
      <br />


      <div *ngFor="let premioRevelacao of this.premiosRevelacao" style="
          background-color: #cc61ce00;
          border-radius: 12px;
          border: 1px solid #04cdcd;
          margin-top: 20px;
          padding-bottom: 6px;
          width: 100%;
        ">
        <div class="row">
          <div class="col-3" style="text-align: center">
            <i class="fa fa-trophy mx-2" style="font-size: 40px"></i>
          </div>
          <div class="col-7">
            <p style="margin-top: 5px; margin-bottom: 0px; margin-left: 10px">
              Premio :
              <span style="margin-left: 10px">{{ premioRevelacao.premio }}</span>
            </p>
            <span style="margin-left: 10px"></span>
          </div>
        </div>
      </div>

      <div class="form-row" style="padding-top: 10px; padding-bottom: 0px; width: 100%; text-align: center;">
        <p style=" margin: auto; ">1 - Você presenteia a mamãe com qualquer valor
        </p>
        <p style=" margin: auto; ">2 - Depois de fechar o carrinho você terá direito a dar seu palpite do sexo do bebê.
        </p>
        <p style=" margin: auto; ">3 - Se você acertar o sexo participa do sorteio, concorrendo aos prêmios acima.</p>

      </div>
      <!-- <p id="rifaMsgOut" style="text-align: center; margin: auto">
        <br /><a
          class="btn btn-outline-primary"
          href="https://www.youtube.com/watch?v=X9FNwLGnAm4&amp;ab_channel=Pipooh-MeuCh%C3%A1Fralda"
          target="_blank"
        >
          Ainda tem dúvidas? Clique aqui.
        </a>
      </p> -->
    </div>
  </div>
</ng-template>

<button id="openModalChaRevelacao" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(chaRevelacao)"
  data-target="#openModalChaRevelacao"></button>

<ng-template #sucessoProduto let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="text-align: center; padding: 10px">
      <h2 style="color: #04cdcd">Produto adicionado com sucesso!</h2>

      <div class="row" *ngIf="this.carrinho">
        <div class="" style="width: 100%">
          <table class="" style="width: 100%">
            <thead>
              <tr>
                <th scope="col">Imagem</th>
                <th scope="col">Tipo</th>
                <th scope="col">Qtd</th>
                <th scope="col">Preço</th>
                <th scope="col">Total</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let car of carrinho">
                <td width="20%">
                  <img *ngIf="!car.fkProdutos_LojaIntegrada" src="{{ this.urlImage(car.ProductsImage) }}" alt=""
                    width="100%" style="border-radius: 20px" />
                  <img *ngIf="car.fkProdutos_LojaIntegrada" src="{{ this.urlImageLoja(car.ProductsImage) }}" alt=""
                    width="100%" style="border-radius: 20px" />
                </td>
                <td>{{ car.CategoriaName }}</td>
                <td>{{ car.quantidade }}</td>
                <td>{{ this.formatDecimal(car.ProductsPrice) }}</td>
                <td>
                  {{ this.formatDecimal(car.ProductsPrice * car.quantidade) }}
                </td>
                <td (click)="removeCarrinho(car)">
                  <i class="fas fa-times" style="color: #842029; cursor: pointer"></i>
                </td>
              </tr>
              <tr style="height: 50px">
                <td colspan="3"></td>
                <td><strong>Total</strong></td>
                <td>R$ {{ this.formatDecimal(this.total) }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px">

        <div class="col-6">
          <a class="close btn btn-carrinho" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="font-size: 13px">
            Continue Comprando
          </a>
        </div>
        <div class="col-6">
          <a *ngIf="this.carrinho.length != 0" href="/carrinho" (click)="modal.dismiss('Cross click')"
            style="font-size: 13px" class="btn btn-primary">Finalizar Carrinho</a>
        </div>
       
      </div>
    </div>
  </div>
</ng-template>

<button id="openModalSucessoProduto" class="btn btn-lg btn-outline-primary" [hidden]="true"
  (click)="open(sucessoProduto)" data-target="#openModalSucessoProduto"></button>

<ng-template #modalProduto let-modal>f
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body info-produto">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div *ngFor="
              let img of product.Imagens;
              let i = index;
              let firstItem = first
            " class="tab-pane active" id="imagem{{ i }}" aria-expanded="true">
            <img *ngIf="!product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i" src="{{ this.urlImage(img) }}"
              width="100%" style="border-radius: 25px" />
            <img *ngIf="product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i"
              src="{{ this.urlImageLoja(img) }}" width="100%" style="border-radius: 25px" />
          </div>

          <ul *ngIf="product"
            class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
            role="tablist">
          </ul>
        </div>
        <div class="col-md-6">
          <h5>{{ this.product.ProductsName }}</h5>
          <div *ngIf="this.more">
            <div [innerHTML]="this.product.ProductsDescription"></div>
          </div>

          <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
          <h4 class="mt-3" style="color: #01b1af">
            R$ {{ this.formatDecimal(this.product.ProductsPrice) }}
          </h4>
          <br />
          <div class="row" style="margin-bottom: 20px">
            <div class="col-12 justify-content-center" style="text-align: center">
              <div class="pro-qty" style="border-radius: 25px">
                <span (click)="changeQtd('down')" class="dec qtybtn">-</span>
                <input name="lblQuantidade" [(ngModel)]="quantidade" type="text" />
                <span (click)="changeQtd('up')" class="inc qtybtn">+</span>
              </div>
              <a (click)="
                  addCarrinho(this.product); modal.dismiss('Cross click')
                " onclick="document.getElementById('openModalSucessoProduto').click();"
                class="btn btn-primary">Adicionar ao carrinho</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
  data-target="#openModalProduto"></button>

<ng-template #modalSaibaMais let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
      style="background-color: #01b1af; border-radius: 50px">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body info-produto">
    <div class="row" style="padding: 20px; padding-right: 30px">
      <div class="col-12">
        <h3 style="text-align: center; color: #f8b3cf">
          Como o meu presente chega na mamãe?
        </h3>
      </div>
      <div class="col-12">
        <ol>
          <li>
            O convidado presenteia a mamãe, seu presente é convertido em
            créditos.
          </li>
          <li>
            A mamãe recebe os créditos para resgatar presentes na lojinha Pipooh
            ou em parceiros quando quiser, sem ocupar espaço em casa.
          </li>
          <li>
            Assim que a mamãe sentir necessidade, ela seleciona os presentes e
            faz o resgate.
          </li>
          <li>Ela recebe em casa ou vai a uma loja física.</li>
          <li>
            Com o seu presente ela ainda acumula pontos e troca por produtos.
          </li>
        </ol>
      </div>
    </div>
  </div>
</ng-template>

<button id="openModalSaibaMais" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalSaibaMais)"
  data-target="#openSaibaMais"></button>

<ng-template #modalPresenca let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="padding: 8px">
      <div class="" style="width: 100%; display: block; text-align: center">
        <h5 class="modal-title" style="
            text-align: center;
            color: #04cdcd;
            font-weight: 900;
            font-size: 25px;
          ">
          Confirme a sua presença no evento
          <br />
        </h5>
      </div>
      <br />

      <div style="
          background-color: #cc61ce00;
          border-radius: 12px;
          border: 1px solid #04cdcd;
          margin-top: 20px;
          padding-bottom: 6px;
          width: 100%;
        ">
        <!-- Inicio // Confirme a sua presença no evento -->
        <div class="row" style="padding: 20px">
          <div class="col-12">
            <div>
              <p style="color: #01b1af">
                Festa: <span *ngIf="this.infoEvento.festaTipo">{{ this.infoEvento.festaTipo }}</span> Data :{{
                this.infoEvento.data | date: "dd-MM-yyyy"
                }}
              </p>
              <p style="color:#01b1af">
                Data limite de confirmação: {{this.infoEvento.dataLimiteConfirmacao | date: "dd-MM-yyyy"}}
              </p>
              <p style="color: #01b1af">
                Endereço:{{ this.infoEvento.endereco }}
              </p>
            </div>
          </div>
        </div>
        <!-- Final // Confirme a sua presença no evento -->
      </div>
      <p style="text-align: center">
        Pedimos que confirme presença apenas uma vez.
      </p>
      <div class="row justify-content-center" style="margin-bottom: 10px">
        <div class="col-lg-12">
          <input type="text" class="input-group" style="
              padding: 5px;
              border-radius: 20px;
              border: 1px solid #04cdcd;
              margin-bottom: 15px;
            " [(ngModel)]="presencaConfirmacao" placeholder="Insira seu nome" />
          <input type="email" class="input-group" style="
              padding: 5px;
              border-radius: 20px;
              border: 1px solid #04cdcd;
              margin-bottom: 15px;
            " [(ngModel)]="presencaEmail" placeholder="Insira seu email (opcional)" />
          <input type="text" class="input-group" mask="(00)00000-0000" style="
              padding: 5px;
              border-radius: 20px;
              border: 1px solid #04cdcd;
              margin-bottom: 15px;
            " [(ngModel)]="presencaTelefone" placeholder="Insira seu telefone" />
            <label for="">Data de nascimento</label>
          <input type="text" class="input-group"   style="
              padding: 5px;
              border-radius: 20px;
              border: 1px solid #04cdcd;
              margin-bottom: 15px;
            "
            placeholder="DD/MM/YYYY" [dropSpecialCharacters]="false" mask="00/00/0000"
             [(ngModel)]="presencaDataNascimento"  />
            
          

          <div>
            <h3 style="text-align: center; color: #f8b3cf">
              Dependentes
            </h3>
            <div class="row" style="padding: 10px;">
              <div class="col-5">

              </div>
              <div class="col-7">
                <div class="pro-qty" style="border-radius: 25px">
                  <span (click)="changeQtdDependentes('down')" class="dec qtybtn">-</span>
                  <input name="dependentes" [(ngModel)]="dependentesQtd" type="text" />
                  <span (click)="changeQtdDependentes('up')" class="inc qtybtn">+</span>
                </div>
              </div>
            </div>

            <mat-accordion *ngFor="let depedente of dependentes; let i = index;">
              <mat-expansion-panel>
                  <mat-expansion-panel-header>
                      <mat-panel-title>
                          Dependente {{i + 1}}
                      </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-form-field style="width: 100%;" class="example-full-width">
                      <mat-label>Nome</mat-label>
                      <input matInput [(ngModel)]="depedente.nome" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                  <mat-form-field style="width: 100%;" class="example-full-width">
                      <mat-label>Data de nascimento*</mat-label>
                      <!-- <input type="text" matInput [(ngModel)]="ingresso.dataNascimento"
                          [ngModelOptions]="{standalone: true}" [dropSpecialCharacters]="false" mask="00/00/0000"
                          placeholder="DD/MM/AAAA"> -->
                      <input type="text" 
                      class="form-control" matInput [ngModelOptions]="{standalone: true}"
                      placeholder="DD/MM/YYYY" [dropSpecialCharacters]="false" mask="00/00/0000"
                          [(ngModel)]="depedente.dataNascimento">
                  </mat-form-field>
              </mat-expansion-panel>
          </mat-accordion>

            <!-- <div class="row" style="padding: 10px;">
              <div class="col-5">
                <label for="">Abaixo dos 18 anos</label>

              </div>
              <div class="col-7">
                <div class="pro-qty" style="border-radius: 25px">
                  <span (click)="changeQtdPresencaCrianca('down')" class="dec qtybtn">-</span>
                  <input name="presencaCrianca" [(ngModel)]="presencaCrianca" type="text" />
                  <span (click)="changeQtdPresencaCrianca('up')" class="inc qtybtn">+</span>
                </div>
              </div>
            </div> -->

            <label for=""><input type="checkbox" [(ngModel)]="termos" id="termosEcondicoes" name="termosEcondicoes"> Eu
              concordo com os <a style="color: #01b1af;"
                href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf" target="_blank">Termos
                e
                condições de uso</a> </label>
            <label for=""><input type="checkbox" [(ngModel)]="politica" id="politica" name="politica"> Eu concordo com
              as <a style="color: #01b1af;"
                href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf"
                target="_blank">Política de privacidade.</a> </label>
            <!-- <p style="text-align: center;">Crianças com mais de 6 anos</p> -->

            <!-- <input type="text" class="input-group" mask="00" style="
                padding: 5px;
                border-radius: 20px;
                border: 1px solid #04cdcd;
                margin-bottom: 15px;
              " [(ngModel)]="presencaCrianca" placeholder="abaixo dos 6 anos" /> -->
          </div>
        </div>
      </div>
      
      <!-- Botão de confirmação de presença -->
      <div style="text-align: center">
        <button  class="btn" [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading }" 
        *ngIf="comparaData(infoEvento.dataLimiteConfirmacao)"
          style="
            background-color: #f8b3cf;
            border-radius: 25px;
            color: white;
            text-align: center;
          " (click)="confirmarPresenca()"> Confirme sua presença! 
        </button>
        <h6 *ngIf="!comparaData(infoEvento.dataLimiteConfirmacao)" 
          style="color: red;
            border-radius: 25px;
            background-color: #f8b3cf;
            height: 30px;
            text-align: center;">
          Data de confirmação limite expirada
        </h6>
        
      </div>
      <!-- Final de confirmação de botão -->
    </div>
  </div>
</ng-template>

<button id="openModalPresenca" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="openMD(modalPresenca)"
  data-target="#openPresenca"></button>

<app-footer></app-footer>