import { Injectable } from '@angular/core';
import { environment_v3 } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ListaVirtualService {
  private URL = `${environment_v3.api}`;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  buscarListaEvento(idEvento): Observable<any> {
    return this.http.get<any>(`${this.URL}api/ListaVirtual/select/lista/evento/${idEvento}`);
  }
}
