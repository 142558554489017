<!-- Start Top Nav -->
<!-- <div style="position: fixed;
top:0;
left: 0; width: 100%;z-index: 10;" class="visible-xs">
  <nav class="navbar navbar-expand-lg navbar-light d-lg-block" id="templatemo_nav_top" style="background-color: white;">
    <div class="container text-light">
      <div class="w-100 d-flex justify-content-between">
        <div class="col-4">
          <img src="assets/img/logopipooh.png" routerLink="/" class="hidden-xs" width="100%">
          <img src="assets/img/logopipooh.png" routerLink="/" class="visible-xs" width="150%">
        </div>
        <div class="col-md-4 col-lg-6"></div>
        <div class="col-md-4 col-lg-2 justify-content-end">
          <a class="nav-link" href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
            style="color: white; background-color: #F8B3CF; border-radius: 25px; width: 100%;text-align: center;">Login</a>
        </div>
  
      </div>
    </div>
  </nav>
      <ul  class="navbar-header">
        <li routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }" routerLink="/eventos" ><span>Eventos |</span></li>
        <li routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }" routerLink="/cartao-presente"><span>Cartão Presente |</span></li>
        <li routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }" routerLink="/produtos"><span>Empresarial |</span></li>
        <li routerLinkActive="m-menu__item--active" routerLinkActiveOptions="{ exact: true }" routerLink="/produtos"><span>Benefícios </span></li>
      </ul>
</div> -->

<!-- Start Top Nav -->
<nav class="navbar navbar-expand-lg navbar-light d-none d-lg-block hidden-xs" id="templatemo_nav_top"
  style="background-color: white;">
  <div class="container text-light">
    <div class="w-100 d-flex justify-content-between">
      <div>
        <i class="fa fa-envelope mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none"
          href="https://wa.me/5511988829676?text=Assunto.">Precisa de ajuda e televendas?</a>
        <i class="fa fa-phone mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="https://wa.me/5511988829676?text=Assunto.">+55
          11 98882 9676</a>

      </div>
      <div class=" ">
        <div class="input-group align-items-center">
          <a class="nav-link" href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
            style="color: white; background-color: #F8B3CF; border-radius: 25px;">Área da Mamãe</a>
          <a class="nav-link" href="https://loja.pipooh.com.br/?ref=cadastro"
            style="color: white; background-color: #F8B3CF; border-radius: 25px; margin-left: 5px;">Área Empresarial</a>

        </div>
      </div>
      <div class=" ">
        <a class="nav-icon position-relative text-decoration-none " href="#" data-bs-toggle="modal"
          data-bs-target="#carrinhoModal">
          <i class="fa fa-fw fa-cart-arrow-down text-dark mr-1" style="font-size: 13px;"></i>
          <span
            class="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{{items?.length||
            '0'}}</span>
        </a>
      </div>
      <div>
        <a class="text-light" href="https://www.facebook.com/MeuChaFralda.com.br/?ref=page_internal" target="_blank"
          rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.instagram.com/pipooh.com.br/" target="_blank"><i
            class="fab fa-instagram fa-sm fa-fw me-2"></i></a>

      </div>
    </div>
  </div>
</nav>
<!-- Close Top Nav -->
<!-- Header -->
<nav class="navbar navbar-expand-lg navbar-light shadow hidden-xs" style="background-color: #fff;">
  <div class="container d-flex justify-content-between align-items-center">
    <a class="navbar-brand text-success logo h1 align-self-center d-none d-lg-block" href="#">
      <img src="assets/img/logopipooh.png" width="50%" style="width: 180px">
    </a>
    
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
      data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="visible-xs" href="#">
      <img src="assets/img/logopipooh.png" width="10%" style="width: 180px">
    </a>
    <a class="nav-icon position-relative text-decoration-none  d-lg-none" href="#" data-bs-toggle="modal"
      data-bs-target="#carrinhoModal">
      <i class="fa fa-fw fa-cart-arrow-down text-dark mr-1"></i>
      <span
        class="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{{items?.length||
        '0'}}</span>
    </a>
    <a class="nav-link btn btn-mamae d-lg-none hidden-xs" target="_blank"
      href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
      style="color: white; padding: 5px;">Área da Mamãe</a>



    <div class=" collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
      <div class="flex-fill">

        <ul class="nav navbar-nav d-flex justify-content-end mx-lg-auto" style="margin: 20px;">

          <li class="nav-item">
            <a class="nav-link d-lg-none" target="_blank"
              href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae">Login</a>
          </li>
          
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link d-lg-none" style="color: #01B1AF;" target="_blank"
              href="https://app.pipooh.com.br/?refRemarketing=site-principal&ref=site-principal">Cadastre-se</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/eventos']">Organizar
              eventos</a>
          </li>
          <!-- &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/cartao-presente']">Cartão Presente</a>
          </li> -->
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/empresarial']">Auxilio Empresarial</a>
          </li>
          
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" href="faq">FAQ</a>
          </li>
          &nbsp;&nbsp;
          &nbsp;&nbsp;
        </ul>
      </div>
      <div class="navbar align-self-center d-flex">

      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-light shadow visible-xs" style="background-color: #fff;position: fixed;
top:0;
left: 0; width: 100%;z-index: 10; margin-bottom: 20px;">
  <div class="container d-flex justify-content-between align-items-center">
    <a class="navbar-brand text-success logo h1 align-self-center d-none d-lg-block" href="#">
      <img src="assets/img/logopipooh.png" width="50%" style="width: 180px">
    </a>
    
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
      data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="visible-xs" href="#">
      <img src="assets/img/logopipooh.png" width="10%" style="width: 180px">
    </a>
    <a class="nav-icon position-relative text-decoration-none  d-lg-none" href="#" data-bs-toggle="modal"
      data-bs-target="#carrinhoModal">
      <i class="fa fa-fw fa-cart-arrow-down text-dark mr-1"></i>
      <span
        class="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{{items?.length||
        '0'}}</span>
    </a>
    <a class="nav-link btn btn-mamae d-lg-none hidden-xs" target="_blank"
      href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
      style="color: white; padding: 5px;">Área da Mamãe</a>



    <div class=" collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
      <div class="flex-fill">

        <ul class="nav navbar-nav d-flex justify-content-end mx-lg-auto" style="margin: 20px;">

          <li class="nav-item">
            <a class="nav-link d-lg-none" target="_blank"
              href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae">Login</a>
          </li>
          
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link d-lg-none" style="color: #01B1AF;" target="_blank"
              href="https://app.pipooh.com.br/?refRemarketing=site-principal&ref=site-principal">Cadastre-se</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/eventos']">Organizar
              eventos</a>
          </li>
          <!-- &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/cartao-presente']">Cartão Presente</a>
          </li> -->
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/empresarial']">Auxilio Empresarial</a>
          </li>
      
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" href="faq">FAQ</a>
          </li>
          &nbsp;&nbsp;
          &nbsp;&nbsp;
        </ul>
      </div>
      <div class="navbar align-self-center d-flex">

      </div>
    </div>
  </div>
</nav>



<!-- Close Header -->
<!-- Modal -->
<div class="modal fade" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form action="" method="get" class="modal-content modal-body border-0 p-0">
      <div class="input-group mb-2">
        <input type="text" class="form-control" id="inputModalSearch" name="q" placeholder="Search ...">
        <button type="submit" class="input-group-text bg-success text-light">
          <i class="fa fa-fw fa-search text-white"></i>
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal fade" id="carrinhoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="input-group mb-2 modal-content modal-body border-0 p-0">

      <style>
        .table-grow th,
        .table-grow td {
          padding: 0.25rem;
        }

        .table-grow td {
          display: table-cell;
          vertical-align: middle;
        }
      </style>
      <table class="table table-striped text-left table-grow text-center">
        <thead>
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col">Tipo</th>
            <th scope="col">Qtd</th>
            <th scope="col">Preço</th>
            <th scope="col">Total</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td width="20%"><img src="{{this.urlImage(item.ProductsImage)}}" alt="" width="100%" /></td>
            <td>{{item.CategoriaName}}</td>
            <td>{{item.quantidade}}</td>
            <td>R$ {{this.formatDecimal(item.ProductsPrice)}}</td>
            <td>R$ {{this.formatDecimal(item.ProductsPrice * item.quantidade)}}</td>
            <td (click)="removeCarrinho(item)"><i class="fas fa-times" style="color:#842029;cursor: pointer;"></i>
            </td>
          </tr>
          <tr style="height: 50px;">
            <td colspan="3"></td>
            <td><strong>Total</strong></td>
            <!-- <td>R$ {{this.formatDecimal(this.total)}}</td> -->
            <td></td>
          </tr>
          <tr style="background-color: transparent;">
            <td colspan="5" class="info-produto">
              <a *ngIf="this.items.length!=0" onclick="document.getElementById('finalizarCarrinho').click();"
                routerLink="/carrinho" class="btn btn-primary">Finalizar resgate</a>
              <button hidden=true id="finalizarCarrinho" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Start Banner Hero -->

<!-- <nav class="navbar navbar-expand-lg navbar-light d-none d-lg-block" id="templatemo_nav_top"
  style="background-color: white;">
  <div class="container text-light">
    <div class="w-100 d-flex justify-content-between">
      <div>
        <i class="fa fa-envelope mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none"
          href="https://wa.me/5511988829676?text=Assunto.">Precisa de ajuda e televendas?</a>
        <i class="fa fa-phone mx-2"></i>
        <a class="navbar-sm-brand text-light text-decoration-none" href="https://wa.me/5511988829676?text=Assunto.">+55
          11 98882 9676</a>

      </div>
      <div class=" ">
        <div class="input-group align-items-center">
          <a class="nav-link" href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
            style="color: white; background-color: #F8B3CF; border-radius: 25px;">Área da Mamãe</a>
          <a class="nav-link" href="https://loja.pipooh.com.br/?ref=cadastro"
            style="color: white; background-color: #F8B3CF; border-radius: 25px; margin-left: 5px;">Área Empresarial</a>

        </div>
      </div>
      <div class=" ">
        <a class="nav-icon position-relative text-decoration-none " href="#" data-bs-toggle="modal"
          data-bs-target="#carrinhoModal">
          <i class="fa fa-fw fa-cart-arrow-down text-dark mr-1" style="font-size: 13px;"></i>
          <span
            class="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{{items?.length||
            '0'}}</span>
        </a>
      </div>
      <div>
        <a class="text-light" href="https://www.facebook.com/MeuChaFralda.com.br/?ref=page_internal" target="_blank"
          rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
        <a class="text-light" href="https://www.instagram.com/pipooh.com.br/" target="_blank"><i
            class="fab fa-instagram fa-sm fa-fw me-2"></i></a>

      </div>
    </div>
  </div>
</nav> -->
<!-- Header -->
<!-- <nav class="navbar navbar-expand-lg navbar-light shadow">
  <div class="container d-flex justify-content-between align-items-center">
    <a class="navbar-brand text-success logo h1 align-self-center d-none d-lg-block" href="#">
      <img src="assets/img/LOGO_CARTEIRA.png" width="50%" style="width: 180px">
    </a>
    <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
      data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="nav-icon position-relative text-decoration-none  d-lg-none" href="#" data-bs-toggle="modal"
      data-bs-target="#carrinhoModal">
      <i class="fa fa-fw fa-cart-arrow-down text-dark mr-1"></i>
      <span
        class="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{{items?.length||
        '0'}}</span>
    </a>
    <a class="nav-link btn btn-mamae d-lg-none"
      href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
      style="color: white; padding: 5px;">Área da Mamãe</a>



    <div class=" collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
      <div class="flex-fill">

        <ul class="nav navbar-nav d-flex justify-content-end mx-lg-auto" style="margin: 20px;">
          <li class="nav-item">
            <a class="nav-link d-lg-none" href="/">Home</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link d-lg-none" style="color: #01B1AF;"
              href="https://app.pipooh.com.br/?refRemarketing=site-principal&ref=site-principal">Cadastre-se</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" fragment="comoFunciona" (click)="ancora('comoFunciona')">Como
              funciona</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" fragment="organize" (click)="ancora('organize')">Organizar
              chá/eventos</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" fragment="parceiro" (click)="ancora('parceiro')">Parceiros</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" href="https://blog.pipooh.com.br" fragment="parceiro">Blog</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" href="produtos">Produtos</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" fragment="empresa" (click)="ancora('empresa')">Empresa</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" fragment="midias" (click)="ancora('midias')">Mídias</a>
          </li>
          &nbsp;&nbsp;
          <li class="nav-item">
            <a class="nav-link" href="faq">FAQ</a>
          </li>
          &nbsp;&nbsp;
          &nbsp;&nbsp;
        </ul>
      </div>
      <div class="navbar align-self-center d-flex">
        

      </div>
    </div>
  </div>
</nav> -->

<!-- Close Header -->
<!-- Modal -->
<div class="modal fade" id="templatemo_search" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form action="" method="get" class="modal-content modal-body border-0 p-0">
      <div class="input-group mb-2">
        <input type="text" class="form-control" id="inputModalSearch" name="q" placeholder="Search ...">
        <button type="submit" class="input-group-text bg-success text-light">
          <i class="fa fa-fw fa-search text-white"></i>
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal fade" id="carrinhoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="w-100 pt-1 mb-5 text-right">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="input-group mb-2 modal-content modal-body border-0 p-0">

      <style>
        .table-grow th,
        .table-grow td {
          padding: 0.25rem;
        }

        .table-grow td {
          display: table-cell;
          vertical-align: middle;
        }
      </style>
      <table class="table table-striped text-left table-grow text-center">
        <thead>
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col">Tipo</th>
            <th scope="col">Qtd</th>
            <th scope="col">Preço</th>
            <th scope="col">Total</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td width="20%"><img src="{{this.urlImage(item.ProductsImage)}}" alt="" width="100%" /></td>
            <td>{{item.CategoriaName}}</td>
            <td>{{item.quantidade}}</td>
            <td>R$ {{this.formatDecimal(item.ProductsPrice)}}</td>
            <td>R$ {{this.formatDecimal(item.ProductsPrice * item.quantidade)}}</td>
            <td (click)="removeCarrinho(item)"><i class="fas fa-times" style="color:#842029;cursor: pointer;"></i>
            </td>
          </tr>
          <tr style="height: 50px;">
            <td colspan="3"></td>
            <td><strong>Total</strong></td>
            <!-- <td>R$ {{this.formatDecimal(this.total)}}</td> -->
            <td></td>
          </tr>
          <tr style="background-color: transparent;">
            <td colspan="5" class="info-produto">
              <a *ngIf="this.items.length!=0" onclick="document.getElementById('finalizarCarrinho').click();"
                routerLink="/carrinho" class="btn btn-primary">Finalizar resgate</a>
              <button hidden=true id="finalizarCarrinho" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Start Banner Hero -->